import { AgdirFormsModule } from '@agdir/agdir-forms';
import { ENVIRONMENT } from '@agdir/environment/angular';
import { AgdirGoogleEnvironment, AgdirMapboxEnvironment } from '@agdir/environment/domain';
import { FILE_MANAGER_PREFIX } from '@agdir/fillagring/angular';
import { provideAgdirTranslationProviders } from '@agdir/i18n/angular';
import { ApiModule } from '@agdir/services';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

import { AgdirSentryEnvironment, provideSentryLoggers } from '@agdir/sentry';

export const appConfig: ApplicationConfig = {
	providers: [
		provideAgdirTranslationProviders(),
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy,
		},
		{ provide: ENVIRONMENT, useValue: environment },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.get(AgdirGoogleEnvironment).reCaptchaV3SiteKey,
		},
		{ provide: FILE_MANAGER_PREFIX, useValue: '/[a-zA-Z0-9]{24}/file-manager' },
		{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },

		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerImmediately',
		}),
		importProvidersFrom(
			NzModalModule, // So it can be used in services where its not possible to import modules
			// BrodsmuleErrorReportModule,
			ApiModule.forRoot(),
			NgxMapboxGLModule.withConfig(environment.get(AgdirMapboxEnvironment)),
			NgxGoogleAnalyticsModule.forRoot(environment.get(AgdirGoogleEnvironment).googleAnalyticsTrackingCode),
			NgxGoogleAnalyticsRouterModule,
			TimeagoModule.forRoot(),
			AgdirFormsModule,
		),
		provideRouter(
			appRoutes,
			withComponentInputBinding(),
			// withDebugTracing(),
			// withPreloading(PreloadAllModules), // Davai
			withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
			// withViewTransitions(),
			withRouterConfig({
				onSameUrlNavigation: 'reload',
				paramsInheritanceStrategy: 'always',
			}),
		),
		provideAnimations(),
	],
};

try {
	if (environment.get(AgdirSentryEnvironment, { throwOnError: !environment.isLocal })) {
		appConfig.providers.push(provideSentryLoggers());
	}
} catch (e) {
	console.error(e);
}
